import React   from "react";
import { Link } from "gatsby";

import "./header.scss";

const Header = () => {
    return (
        <div className={"header"}>
            <h1 className={"header__title font-primary"}>TOMMY GENES</h1>

            <nav className={"header__nav header__nav--desktop font-primary"}>
                <ol>
                    <li><Link to={"/"} activeClassName={"nav-active-link"}>Main</Link></li>
                    <li><Link to={"/film/"} activeClassName="nav-active-link">Index</Link></li>
                    <li><Link to={"/information/"} activeClassName={"nav-active-link"}>Information</Link></li>
                </ol>
            </nav>

            <nav className={"header__nav header__nav--mobile font-primary"}>
                <ol>
                    <li><Link to={"/information/"} activeClassName={"nav-active-link"}>Tommy Genes</Link></li>
                    <li><Link to={"/"} activeClassName={"nav-active-link"}>Close</Link></li>
                </ol>
            </nav>

        </div>
    );
}

export default Header;
