import React from "react";
import PropTypes from "prop-types";
import { throttle } from 'throttle-debounce';

import './screensaver.scss';
import ViewportContext from "../utils/viewportContext";

const TIME_UNTIL_DISPLAY_MS = 15 * 1000;

function formatTimeOnSite(elapsedMs) {
    const roundedMilliseconds = (elapsedMs % 1000) / 10;
    const formattedMilliseconds = (roundedMilliseconds < 10 ? '0' : '') + roundedMilliseconds;

    const seconds = ((elapsedMs % 60000) / 1000).toFixed(0);
    const formattedSeconds = (seconds < 10 ? '0' : '') + seconds;

    const minutes = Math.floor(elapsedMs/60000);
    const formattedMinutes = (minutes < 10 ? `0` : '') + minutes;

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
}

class Screensaver extends React.Component {
    static contextType = ViewportContext;

    constructor(props) {
        super(props);

        this._handleMouseMove = this._handleMouseMove.bind(this);

        this._mouseMoveThrottle = throttle(500, false, this._handleMouseMove);

        this.state = {
            timeOnSiteMs: 0,
            lastActivityTime: 0,
        };
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                timeOnSiteMs: this.state.timeOnSiteMs + 100
            });
        }, 100);
    }

    _handleMouseMove() {
        this.setState({
            lastActivityTime: this.state.timeOnSiteMs
        });
    }

    _shouldRenderScreensaver() {
        const { lastActivityTime, timeOnSiteMs } = this.state;

        return ((timeOnSiteMs - lastActivityTime) >= TIME_UNTIL_DISPLAY_MS) && !this.props.disable;
    }

    _renderScreensaver() {
        return (
            <div className="screensaver">
                <span className={"screensaver__text font-primary"}>
                    {formatTimeOnSite(this.state.timeOnSiteMs)}
                </span>
            </div>
        );
    }

    render() {
        let trackingEvents = {};
        if (this.context === 'largeScreen') {
            trackingEvents.onMouseMove = this._mouseMoveThrottle;
        } else {
            trackingEvents.onClick = this._mouseMoveThrottle;
            trackingEvents.onTouchMove = this._mouseMoveThrottle;
        }

        return (
            <div {...trackingEvents}>
                {this._shouldRenderScreensaver() && this._renderScreensaver()}

                {this.props.children}
            </div>
        );
    }
}

Screensaver.propTypes = {
    disable: PropTypes.bool,
}

export default Screensaver;
