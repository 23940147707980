import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withPrefix } from "gatsby"

import "./page-transition.scss";

const ANIMATION_TIME_MS = 1500;

const TOP_PATHS = [
    withPrefix("/"),
    withPrefix("/film/"),
    withPrefix("/information/"),
];

class TGPageTransition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastLocation: props.location.pathname
        };

        this._shouldIncludeTransitionHandling = this._shouldIncludeTransitionHandling.bind(this);
    }

    componentDidMount() {
        this.setState({
            lastLocation: this.props.location.pathname
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setState({
            lastLocation: this.props.location.pathname
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (this.props.location.pathname !== nextProps.location.pathname);
    }

    _shouldIncludeTransitionHandling() {
        return TOP_PATHS.includes(this.props.location.pathname);
    }

    _renderPage() {
        return (
            <div className="page__content">
                {this.props.children}
            </div>
        );
    }

    render() {
        if (this._shouldIncludeTransitionHandling()) {
            return (
                <TransitionGroup className={`page-wrapper`} key={"wrapper"}>
                    <CSSTransition key={this.props.location.pathname}
                                   className={`page`}
                                   classNames='page--transition'
                                   timeout={ANIMATION_TIME_MS}
                    >
                        {this._renderPage()}
                    </CSSTransition>
                </TransitionGroup>
            );
        }

        return (
            <div className={`page-wrapper`} key={"wrapper"}>
                <div className={"page"}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default TGPageTransition;
