import React from "react";

import './splash.scss';

const Splash = () => {
    return (
        <div className="splash">
            <h1 className={"splash__content font-primary"}>TOMMY GENES</h1>
        </div>
    );
};

export default Splash;
