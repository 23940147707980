import React, { useEffect, useState } from "react";

import Header from "../components/header/header";
import Screensaver from "../components/screensaver/screensaver";
import TGPageTransition from "../components/tgPageTransition/index";
import { isFilmDetailPathname } from "../components/utils/helpers";
import ViewportContext from "../components/utils/viewportContext";
import Splash from "../components/splash/splash";

import "./layout.css";
import "./fonts.css";
import "../components/utils/media-queries.scss";

const BREAKPOINTS = {
    tablet: 768,
    desktop: 1025,
};

const FORCE_DISABLE_SCREENSAVER = false;
const SPLASH_TIMEOUT = 1500;

function getViewportMode() {
    const viewportWidth = window.innerWidth;

    if (viewportWidth >= BREAKPOINTS.desktop) {
        return 'largeScreen';
    } else if (viewportWidth >= BREAKPOINTS.tablet) {
        return 'mediumScreen';
    } else {
        return 'smallScreen';
    }
}

const Layout = ({ location, children }) => {
    const [viewport, setViewport] = useState('smallScreen');

    const onFilmDetailPage = isFilmDetailPathname(location.pathname);
    const disableScreensaver = !!onFilmDetailPage || FORCE_DISABLE_SCREENSAVER;

    const [displaySplash, setDisplaySplash] = useState(true);

    useEffect(() => {
        setTimeout( () => {
            setDisplaySplash(false);
        }, SPLASH_TIMEOUT);

        if (typeof window !== 'undefined') {
            setViewport(getViewportMode());
            window.addEventListener('resize', () => setViewport(getViewportMode()));
        }
    }, []);

    return (
        <ViewportContext.Provider value={viewport}>
            {displaySplash && <Splash />}

            <Screensaver disable={disableScreensaver}>
                <TGPageTransition location={location}>
                    {!onFilmDetailPage && <Header />}

                    <main>{children}</main>
                </TGPageTransition>
            </Screensaver>
        </ViewportContext.Provider>
    );
};

export default Layout;
